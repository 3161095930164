
import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Crypto from "crypto";

@Component({
  components: {FormWrapper}
})
export default class TakipHazirlaDayanakEkleForm extends Mixins(SimpleInputMixin) {
  localValue: Array<File> = [];

  fileRule(dosyalar: Array<File>, zorunluMu: boolean) {
    if (dosyalar && dosyalar.length > 0) {
      for (const dosya of dosyalar) {
        let uzanti = dosya.name.split('.').pop();
        if (["udf", "pdf", "jpg", "jpeg", "png", "tif", "tiff", "UDF", "PDF", "JPG", "JPEG", "PNG", "TIF", "TIFF"].indexOf(uzanti ?? '') >= 0) {
          if (dosya.size < 10485760)
            return true;
          else
            return "Doküman boyutu 10MB'dan fazla olamaz.";
        } else
          return "Doküman formatı UYAP'a uygun değil."
      }
    } else {
      if (zorunluMu)
        return 'Lütfen bu alanı doldurun.';
      else
        return true;
    }
  }

  async save() {
    let filesValue: Array<{ data: string, name: string, mimeType: string, dosya: File | null, hash: string | null }> = []
    for (const file of this.localValue) {
      // @ts-ignore
      let base64File: string = await this.$helper.convertToBase64(file);
      let hash = Crypto.createHash('sha1').update((base64File.split(','))[1]).digest().toString('hex');
      let fileValue = {
        data: (base64File.split(','))[1],
        name: file.name,
        mimeType: file.type,
        dosya: file,
        hash: hash
      }
      filesValue.push(fileValue)
    }
    this.$emit('onSuccess', filesValue);
  }
}
