
import {Component, Mixins, Prop} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import Crypto from "crypto";
import NewFormWrapper from "@/components/NewFormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: {NewFormWrapper, FormWrapper}
})
export default class TakipHazirlaEvrakEkleForm extends Mixins(ObjectInputMixin) {
  fileRule(dosya: any, zorunluMu: boolean) {
    if (dosya) {
      let uzanti = dosya.name.split('.').pop();
      if (["udf", "pdf", "jpg", "jpeg", "png", "tif", "tiff", "UDF", "PDF", "JPG", "JPEG", "PNG", "TIF", "TIFF"].indexOf(uzanti) >= 0) {
        if (dosya.size < 10485760)
          return true;
        else
          return "Doküman boyutu 10MB'dan fazla olamaz.";
      } else
        return "Doküman formatı UYAP'a uygun değil."
    } else {
      if (zorunluMu)
        return 'Lütfen bu alanı doldurun.';
      else
        return true;
    }
  }

  async onDosyaChange(file: File) {
    if (file) {
      let base64File: string = await this.$helper.convertToBase64(file);
      this.localValue.data = (base64File.split(','))[1];
      this.localValue.hash = Crypto.createHash('sha1').update((base64File.split(','))[1]).digest().toString('hex');
      this.localValue.name = file.name;
      this.localValue.mimeType = file.type;
      this.input();
    } else {
      this.localValue = {data: null, name: '', mimeType: '', dosya: null};
      this.input();
    }
  }
}
