

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipHazirlaDayanakEkleForm from "@/components/eicrapro/TakipHazirlaDayanakEkleForm.vue";
import NewFormWrapper from "@/components/NewFormWrapper.vue";

@Component({
  components: {NewFormWrapper, TakipHazirlaDayanakEkleForm, FormDialog, DeleteDialog, FormWrapper}
})
export default class DayanakList extends Mixins(SimpleInputMixin) {
  @Prop() takipAc!: boolean | string;
  isReady = false;

  headers = [
    {text: 'Evrak Adı', value: 'name'},
    {text: 'İşlemler', value: 'actions', sortable: false, width: '100'}
  ]

  get isTakipAc() {
    return this.takipAc || this.takipAc === "";
  }

  async mounted() {
    if (this.isTakipAc) {
      await this.loadTakipAcData();
    } else
      this.isReady = true
  }

  async loadTakipAcData() {
    this.isReady = false;
    for (let dayanak of this.localValue) {
      dayanak.dosya = await this.$helper.getBlobFromLink('/api/v1/file/download/' + dayanak.file_path.replace('uploads/', ''));
      dayanak.mimeType = dayanak.dosya.type;
      let base64: any = await this.$helper.convertToBase64(dayanak.dosya);
      dayanak.data = (base64.split(','))[1];
    }
    this.input();
    this.isReady = true;
  }

  deleteDayanak(index: number) {
    this.localValue.splice(index, 1);
  }

  addDayanak(dayanaklar: Array<any>) {
    dayanaklar.forEach(dayanak => {
      this.localValue.push(dayanak);
    })
  }

  converB64Data(item: any): string {
    return 'data:' + item.mimeType + ';base64,' + item.data;
  }
}
